.container {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);

  h1 {
    font-family: Georgia;

    img {
      width: 200px;
    }
    margin-bottom: 45px;
  }

  p {
    margin-bottom: 1rem;
  }

  button {
    margin-top: 3rem;
    font-size: 1rem;
    background: #000;
    color: white;
    border: 0;
    padding: 10px 15px;
    border-radius: 10px;
    cursor: pointer;
  }
}
